<template>
    <div class="h-100">
        <div :class="toolbarClass" class="d-flex">
            <slot name="toolbar" :search="search">
                <div class="col">   
                    <OCompleteStructureSearch v-if="fullStructureSearch" :dataObject="dsTree" field="OrgUnit" class="form-control form-control-sm" :placeholder="$t('Search...')" />
                    <SearchInput v-else class="form-control-sm" @onSearch="search" />
                </div>
                <div v-if="$slots.toolbarActions" class="col-auto d-flex align-items-center gap-2">
                    <slot name="toolbarActions"></slot>

                    <span v-if="showIncludeClosedToggle" class="form-check form-switch d-flex gap-1 align-items-center ms-3 me-3 ">
                        <input id="include-closed-projects" class="form-check-input me-1" type="checkbox" v-model="includeClosed" @change="toggleIncludeClosed"/>
                        <label for="include-closed-projects" class="form-check-label">
                            {{ $t("Include Closed") }}
                        </label>
                    </span>
                </div>
            </slot>
        </div>
        <div class="d-flex" style="height: calc(100% - 48px); ">
            <ODataGrid :dataObject="dsTree"
                ref="treeGridRef"
                hideMultiselectColumn
                hideGridMenu
                hideSystemColumn
                hideActionColumn
                disableNavigation
                disableFilterRow
                noDetailFooter
                :rowclickhandler="(e)=>{ onRowClick(e); }"
                :whereClause = " props.whereClause"
            >
                <ONodeColumn :field="nodeData.displayField" :headerName="$t(nodeData.HeaderName)" boldDisplay sortable hideCount :filter="false" :cellTitle="row => `ID: ${row.ID}, ${row.NameAndTitle}`" sortField="ID" width="400">
                    <template #default="{row}">
                        <OExpandableCell :row="row" :class="{ 'closed' : row.Closed}">
                            <template #value>
                                <span  class="cp">
                                    <a v-if="!row.Closed && showAddNewSubOrgunit && dsTree.current.ID == row.ID" :title="$t('Add New Sub Orgunit')" class="float-end" @click="emit('onNewSubOrgUnit', row);"> <i class="bi bi-plus-circle"></i> </a>
                                    <span  :class="{'fw-bold' : row.HasSubNodes, 'closed' : row.IsClosed}">
                                        {{ row[nodeData.displayField] }}
                                    </span>
                                    <span v-if="row.Closed" class="text-secondary ms-1">
                                        (Closed)
                                    </span>
                                </span>
                            </template>

                            <template #default>
                                <span :class="{ 'closed' : row.Closed }" >
                                    <span class="cp">
                                    <a v-if="!row.Closed && showAddNewSubOrgunit && dsTree.current.ID == row.ID" :title="$t('Add New Sub Orgunit')" class="float-end" @click="emit('onNewSubOrgUnit', row);"> <i class="bi bi-plus-circle"></i> </a>
                                        {{ row[nodeData.displayField] }}
                                    </span>
                                    <span v-if="row.Closed" class="text-secondary ms-1">
                                        (Closed)
                                    </span>
                                </span>
                            </template>
                        </OExpandableCell>
                    </template>
                </ONodeColumn>
                    <OColumn field="ID" :headerTitle="$t('ID')" :headerName="$t('ID')" width="70"/>
                    <OColumn field="Name" :headerTitle="$t('Name')" :headerName="$t('Name')" hide/>
                    <OColumn field="Title" :headerTitle="$t('Title')" :headerName="$t('Title')" hide/>
                    <OColumn field="NamePrefix" :headerTitle="$t('Prefix')" :headerName="$t('Prefix')" hide/>
                    <OColumn field="UnitType" :headerTitle="$t('Unit Type')" :headerName="$t('Unit Type')" width="110"/>
                    <OColumn field="Company" :headerTitle="$t('Company')" :headerName="$t('Company')" hide/>
                    <OColumn field="Parent" :headerTitle="$t('Parent')" :headerName="$t('Parent')" hide/>
                    <OColumn field="PlannedEndDate" :headerTitle="$t('Planned End Date')" :headerName="$t('Planned End Date')" hide/>
                    <OColumn field="Closed" :headerTitle="$t('Closed')" :headerName="$t('Closed')" hide/>
                    <OColumn field="IdPath" :headerTitle="$t('Id Path')" :headerName="$t('Id Path')" hide/>
                    <OColumn field="NamePath" :headerTitle="$t('Name Path')" :headerName="$t('Name Path')" hide/>
                    <OColumn field="AccessIdPath" :headerTitle="$t('Access Id Path')" :headerName="$t('Access Id Path')" hide/>
                    <OColumn field="AllowAsContext" :headerTitle="$t('Allow As Context')" :headerName="$t('Allow As Context')" hide/>
                    <OColumn field="Updated" :headerTitle="$t('Updated')" :headerName="$t('Updated')" hide/>
            </ODataGrid>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { createWhereClause, getTreeDataSource, doSearch, defaultNodeData } from 'o365.modules.OrgUnitsTreeConfig.ts';
import $t from "o365.modules.translate.ts";
import { ONodeColumn, OExpandableCell, OCompleteStructureSearch } from 'o365-nodedata';
import SearchInput from 'o365.vue.components.SearchInput.vue';

const emit = defineEmits(['onIndexChange', 'onRowClick', 'toggleIncludeClosed', 'onNewSubOrgUnit']);
const treeGridRef = ref(null);
let prevWhereClause: Array<string> = [];
const props = defineProps({
    /*
        Declare on what field to display and 
    */
    nodeData: {
        type: Object,
        default: () => defaultNodeData,
    },
    whereClause: {
        type: String
    },
    /*
        Default toolbarClass 
    */
    toolbarClass: {
        default: 'p-2 bg-light-subtle border-bottom row',
    },
    highlightActiveNode: {
        type: Boolean,
        default: false
    },
    /** 
    *   Expands tree to selected level
    */
    expandToLevel: {
        type: Number,
        default:  1
    },
    /** 
    *   IsClosed functionality
    */
    includeClosed:{
        type: Boolean,
        default: false
    },
    showIncludeClosedToggle: {
        type: Boolean,
        default: false
    },
    showAddNewSubOrgunit: {
        type: Boolean,
        default: false
    },
    /**
     * Use full structure search instead of filter
     */
    fullStructureSearch: {
        type: Boolean,
        default: false
    }
});

const includeClosed = ref(props.includeClosed);
const dsTree = getTreeDataSource(createWhereClause(props.whereClause));

function setTreeFilter(){
    prevWhereClause = [];
    if(dsTree.recordSource.whereClause != ''){
        prevWhereClause.push(dsTree.recordSource.whereClause);
    }
    
    if (!prevWhereClause.includes("IsClosed = 0") && !includeClosed.value) {
         prevWhereClause.push(`IsClosed = 0` );
    }
    dsTree.recordSource.whereClause = prevWhereClause.join(' AND ');
}

function enableTree() { 
    
    dsTree.nodeData.enable();
    const mergedNodeData = Object.assign({}, defaultNodeData, props.nodeData);
    dsTree.nodeData.addConfiguration(mergedNodeData);

    dsTree.nodeData.disableDetailsMultiSelect = true;

}
function onRowClick(item) {

    dsTree.setCurrentIndex(item.index)

    /*
        emiting function on row click.
    */
    emit('onRowClick', item);
}

enableTree();
setTreeFilter(); 

function toggleIncludeClosed() {
    if (includeClosed.value) {
        prevWhereClause = prevWhereClause.filter(e => e !== 'IsClosed = 0');
    } else {
        if (!prevWhereClause.includes('IsClosed = 0')) {
            prevWhereClause.push('IsClosed = 0');
        }
    }

    let currentIndex = dsTree.currentIndex;
    dsTree.recordSource.whereClause = prevWhereClause.join(' AND ');
    dsTree.load().then(()=>{
        dsTree.setCurrentIndex(currentIndex)
    });
    
    emit('toggleIncludeClosed', includeClosed.value);
}

function search(pValue){
    doSearch(pValue);
    let currentIndex = dsTree.currentIndex;
    dsTree.load().then(()=>{
        dsTree.setCurrentIndex(currentIndex)
    });
}

useDataObjectEventListener(dsTree, 'CurrentIndexChanged', ()=>{

    /*
        emiting funtion after index change.
    */
    emit('onIndexChange', dsTree);

    } 
);


useDataObjectEventListener(dsTree, 'DataLoaded', async ()=>{

    /*
        emiting funtion after tree is empty.
    */

    if(dsTree.data.length <1 ){
        emit('onIndexChange');
    }
    //Expand to level
    if( props.expandToLevel && props.expandToLevel !== 1){
        dsTree.nodeData.expandToLevel(props.expandToLevel - 1);
    }

    } 
);

</script>

<style scoped>
.cp{
    cursor: pointer;
}
.closed{
    font-style: italic;
}

</style>
